import * as React from "react"
import {
  CUSTOM_ADDRESS,
  CUSTOM_NUMBER,
  CUSTOM_OPTIONS,
  CUSTOM_URL,
  NUMBER_RATTING,
  SLIDER_RATTING,
  STAR_RATTING,
} from "../contactHelper"
import { Form } from "react-bootstrap"
import StarRatting from "../../Global/Rattings/starRatting"
import SliderRatting from "../../Global/Rattings/sliderRatting"
import NumberRatting from "../../Global/Rattings/numberRatting"

const CustomFields = ({ customFields }) => {
  return (
    <div className="row">
      {customFields.map((customField, customFieldIndex) => {
        let customFieldName = "customFields[" + customFieldIndex + "]"
        return (
          <div className="col-lg-3">
            <div className="mb-3">
              <Form.Control
                type="hidden"
                name={`${customFieldName}[customFieldId]`}
                className="d-none"
                value={customField.id}
              />
              {customField.type === CUSTOM_ADDRESS && (
                <>
                  <div>{customField.label}</div>
                  <textarea
                    className="form-control"
                    name={`${customFieldName}[value]`}
                    style={{ height: "100px" }}
                  />
                </>
              )}

              {customField.type === CUSTOM_NUMBER && (
                <>
                  <div>{customField.label}</div>
                  <input type={"number"} className="form-control" name={`${customFieldName}[value]`} />
                </>
              )}

              {customField.type === CUSTOM_OPTIONS && (
                <>
                  <div>{customField.label}</div>
                  <select className="form-control" name={`${customFieldName}[value]`}>
                    <option value={""}>Please select</option>
                    {customField.defaultValue.split(",").map(option => (
                      <option key={`select_option_${option.trim()}`} value={option.trim()}>
                        {option.trim()}
                      </option>
                    ))}
                  </select>
                </>
              )}

              {customField.type === CUSTOM_URL && (
                <>
                  <div>{customField.label}</div>
                  <input
                    type={"text"}
                    placeholder={"https://"}
                    className="form-control"
                    name={`${customFieldName}[value]`}
                  />
                </>
              )}

              {customField.type === STAR_RATTING && (
                <>
                  <div>{customField.label}</div>
                  <StarRatting
                    defaultRatting={customField.defaultValue}
                    ratingName={`${customFieldName}[value]`}
                  />
                </>
              )}

              {customField.type === SLIDER_RATTING && (
                <>
                  <div>{customField.label}</div>
                  <SliderRatting
                    defaultRatting={customField.defaultValue ? customField.defaultValue : 0}
                    ratingName={`${customFieldName}[value]`}
                  />
                </>
              )}

              {customField.type === NUMBER_RATTING && (
                <>
                  <div>{customField.label}</div>
                  <NumberRatting
                    defaultRatting={customField.defaultValue}
                    ratingName={`${customFieldName}[value]`}
                  />
                </>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CustomFields
