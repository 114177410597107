import * as React from "react"
import _uniqueId from "lodash/uniqueId"
import { useEffect, useState } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import {
  DEFAULT_CONTACT_SOCIAL_LINK_OPTION,
  CONTACT_SOCIAL_LINK_OPTIONS,
} from "../../../services/globalStaticData"

const ContactAddSocialRow = ({ socialLinkRows, setSocialLinkRows, rowId, socialLinkErrors, loopIndex }) => {
  const [selectedSocialIcon, setSelectedSocialIcon] = useState(DEFAULT_CONTACT_SOCIAL_LINK_OPTION.icon),
    [selectedSocialLabel, setSelectedSocialLabel] = useState(DEFAULT_CONTACT_SOCIAL_LINK_OPTION.label),
    [selectedSocialType, setSelectedSocialType] = useState(DEFAULT_CONTACT_SOCIAL_LINK_OPTION.type),
    [addRow, setAddRow] = useState(false),
    [removeRow, setRemoveRow] = useState(null)
  const addNewSocialRow = () => {
    setSocialLinkRows([...socialLinkRows, _uniqueId("contact_add_data_row_")])
  }

  const removeSocialRow = removeIndex => {
    setRemoveRow(null)
    let tempDataRows = []

    socialLinkRows.map((dataRow, dataRowIndex) => {
      if (removeIndex !== dataRow) {
        tempDataRows = [...tempDataRows, dataRow]
      }

      if (dataRowIndex === socialLinkRows.length - 1) {
        setSocialLinkRows([...tempDataRows])
      }
    })
  }

  // alert(rowId);
  useEffect(() => {
    if (addRow) {
      setAddRow(false)
      addNewSocialRow()
    }

    if (removeRow) {
      removeSocialRow(removeRow)
    }
  })

  return (
    <React.Fragment>
      <div className="input-group mb-3">
        <div className="dropdown addNewPhoneBox">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className={selectedSocialIcon} />
            <span>{selectedSocialLabel}</span>
          </button>
          <ul className="dropdown-menu">
            {CONTACT_SOCIAL_LINK_OPTIONS.map((socialOption, socialOptionIndex) => {
              return (
                <li
                  key={socialOptionIndex}
                  onClick={() => {
                    setSelectedSocialIcon(socialOption.icon)
                    setSelectedSocialLabel(socialOption.label)
                    setSelectedSocialType(socialOption.type)
                  }}
                >
                  <a className="dropdown-item" href="#">
                    <i className={socialOption.icon} /> {socialOption.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <Form.Control
          type="url"
          className="form-control social_url"
          placeholder="Full URL or Username"
          name={`socialLinks[${rowId}][url]`}
        />
        <Form.Control
          type="hidden"
          name={`socialLinks[${rowId}][type]`}
          defaultValue={selectedSocialType}
          value={selectedSocialType}
        />

        {rowId === "contact_add_data_row_0" && (
          <OverlayTrigger placement="top" overlay={<Tooltip>Add social url</Tooltip>}>
            <button
              type="button"
              className="btnAddNewSocialUrl btnAddNewGreenDashed"
              onClick={() => setAddRow(true)}
            >
              <i className="bi bi-plus" />
            </button>
          </OverlayTrigger>
        )}

        {rowId !== "contact_add_data_row_0" && (
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete social url</Tooltip>}>
            <button type="button" className="btnDeleteNewSocialUrl" onClick={() => setRemoveRow(rowId)}>
              <i className="bi bi-x-lg" />
            </button>
          </OverlayTrigger>
        )}
      </div>

      {socialLinkErrors[loopIndex] && socialLinkErrors[loopIndex].length > 0 && (
        <React.Fragment>
          {socialLinkErrors[loopIndex].map(socialLinkError => (
            <div className="mb-3 error-text">{socialLinkError}</div>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ContactAddSocialRow
