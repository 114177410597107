import * as React from "react"
import queryString from "query-string"

import PrivateRoute from "../../../components/privateRoute"
import ContactAdd from "../../../components/Contacts/Add/contactAdd"
import ReduxWrapper from "../../../redux/reduxWrapper"

const Contact = props => {
  return (
    <PrivateRoute
      component={ContactAdd}
      location={props.location}
      selectedContactId={props.id}
      groupId={queryString.parse(props.location.search).groupId}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<Contact {...props} />} />
export default WrappedPage

export const Head = () => <title>Add New Contact</title>
