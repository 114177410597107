import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
// import { createTagString } from '../../../services/tagsHelper'

const ContactAddTag = ({
  addTag,
  setAddTag,
  newTag,
  setNewTag,
  tempTags,
  setTempTags,
  removeTagIndex,
  setRemoveTagIndex,
}) => {
  // const [addTag, setAddTag] = useState(false),
  //   [newTag, setNewTag] = useState(""),
  //   [tempTags, setTempTags] = useState([]),
  //   [removeTagIndex, setRemoveTagIndex] = useState(null)
  // [plainTags, setPlainTags] = useState(createTagString(tags))

  // [uiBlockClasses, setUiBlockClasses] = useState("CD_PrivateTag_Container")

  useEffect(() => {
    if (addTag) {
      setAddTag(false)
      setTempTags([
        ...tempTags,
        {
          tag: newTag,
          score: 0,
        },
      ])
      setNewTag("")
    }
    if (removeTagIndex) {
      setRemoveTagIndex(null)
      let tempTag = []

      tempTags.map((tagArray, arrayIndex) => {
        if (parseInt(removeTagIndex) !== arrayIndex) {
          tempTag = [...tempTag, tagArray]
        }
        if (arrayIndex === tempTags.length - 1) {
          setTempTags([...tempTag])
        }
      })
    }
  })

  return (
    <>
      <div className="CD_PrivateTag_Container d-none">
        <div className="SwiftCloudTableTags">
          <div className="SwiftCloudTableTags">
            <ul>
              {tempTags.length > 0 &&
                tempTags.map(tagDetail => (
                  <li key={tagDetail.tag}>
                    <a href="#" data-tagname="Designer">
                      {tagDetail.tag}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="CD_PrivateTag_Editor position-relative">
        <div className="bootstrap-tagsinput">
          {tempTags.length > 0 &&
            tempTags.map((tagDetail, tagIndex) => (
              <span className="tag label label-info" key={tagDetail.tag}>
                {tagDetail.tag}
                <span data-role="remove" onClick={() => setRemoveTagIndex(`${tagIndex}`)} />
                <span data-role="drag" />
              </span>
            ))}
          <Form.Control
            type="text"
            id="contact_tag"
            placeholder="Tag"
            data-toggle="tagsinput"
            name="tags"
            value={newTag}
            defaultValue={newTag}
            onKeyDown={e => {
              if (e.code === "Tab") {
                setAddTag(true)
              }
            }}
            onChange={e => {
              setNewTag(e.target.value)
            }}
          />
        </div>
      </div>
    </>
  )
}

export default ContactAddTag
