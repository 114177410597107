import * as React from "react"
import Layout from "../../Layout/layout"
import { navigate } from "gatsby"
import { getAccessToken, getLoginUserId } from "../../../services/auth"
import { useEffect, useRef, useState } from "react"
import "../contacts.css"
import ContactAddSocialRow from "./contactAddSocialRow"
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import {
  PRIVACY_PUBLIC,
  CONTACT_PHONE_OPTIONS,
  CONTACT_ADDRESS_OPTIONS,
  DEFAULT_CONTACT_PHONE_OPTION,
  DEFAULT_CONTACT_ADDRESS_OPTION,
} from "../../../services/globalStaticData"
import { refactorCreateContactTags } from "../../../services/tagsHelper"
import validator from "validator"
import CustomFields from "./customFields"
import SliderRatting from "../../Global/Rattings/sliderRatting"

import { CONTACT_TYPE_COMPANY, CONTACT_TYPE_HUMAN, updateContactType, validatePhone } from "../contactHelper"
import AxiosInstance from "../../../services/axiosInstance"
import ContactAddTag from "./contactAddTag"

import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete"

const ContactAdd = ({ selectedContactId, groupId }) => {
  const [selectedPhoneLabel, setSelectedPhoneLabel] = useState(DEFAULT_CONTACT_PHONE_OPTION.label),
    [selectedPhoneIcon, setSelectedPhoneIcon] = useState(DEFAULT_CONTACT_PHONE_OPTION.icon),
    [selectedPhoneType, setSelectedPhoneType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [selectedAddressLabel, setSelectedAddressLabel] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.label),
    [selectedAddressType, setSelectedAddressType] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type),
    [socialLinkRows, setSocialLinkRows] = useState(["contact_add_data_row_0"]),
    [formError, setFormError] = useState(""),
    [nameError, setNameError] = useState(""),
    [emailErrors, setEmailErrors] = useState([]),
    [phoneErrors, setPhoneErrors] = useState([]),
    [addressErrors, setAddressErrors] = useState([]),
    [socialLinkErrors, setSocialLinkErrors] = useState([]),
    [uploadedFile, setUploadedFile] = useState(null),
    [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif"),
    [uploadFile, setUploadFile] = useState(false),
    [contactType, setContactType] = useState(CONTACT_TYPE_HUMAN),
    [address, setAddress] = useState("")

  const [addTag, setAddTag] = useState(false),
    [newTag, setNewTag] = useState(""),
    [tempTags, setTempTags] = useState([]),
    [removeTagIndex, setRemoveTagIndex] = useState(null)

  const [settingCustomFields, setSettingCustomFields] = useState([])
  const [loadSettingCustomFields, setLoadSettingCustomFields] = useState(true)

  const handleChange = address => {
    console.log(address)
    setAddress(address)
  }

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        setAddress(results[0].formatted_address)
      })
      .catch(error => console.error("Error", error))
  }

  const getSettingCustomFields = () => {
    return new Promise(async () => {
      await AxiosInstance.get("/settings/custom-field", {
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
      }).then(function (response) {
        if (response.status === 200) {
          setSettingCustomFields(response.data)
        }
      })
    })
  }

  const submitContact = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    let data = {
      name: formData.get("name"),
      email: formData.get("email"),
      note: formData.get("note"),
      profilePic: uploadedFile,
      role: formData.get("role"),
      companyName: formData.get("companyName"),
      jobTitle: formData.get("jobTitle"),
      jobRole: formData.get("jobRole"),
      raveScore: formData.get("raveScore"),
      contactType: CONTACT_TYPE_HUMAN,
      tags: tempTags,
      phone: {
        type: selectedPhoneType,
        phone: formData.get("phone"),
        permission: PRIVACY_PUBLIC,
      },
      address: {
        type: selectedAddressType,
        address: formData.get("address"),
        permission: PRIVACY_PUBLIC,
      },
      metaData: [],
      customFields: [],
    }

    if (sequenceRowActive) {
      data.sequence = formData.get("contactSequence")
    }

    if (taskRowActive && formData.get("task")) {
      data.task = formData.get("task")
    }

    if (financeRowActive) {
      data.finance = {
        singlePrice: formData.get("singlePrice"),
        invoiceType: formData.get("invoiceType"),
        invoiceStatus: formData.get("invoiceStatus"),
      }
    }

    if (calendarRowActive) {
      // calendar validation is pending,
      // data is just added to payload
      // never used in backend also
      // scheduleDate - calendar is also pending.

      data.calendar = {
        schedulerList: formData.get("scheduler_list"),
        eventTitle: formData.get("event_title"),
        inviteShareOpt: formData.get("inviteShareOpt"),
        suggestedDate: formData.get("suggested_date"),
        eventNote: formData.get("event_note"),
        scheduleTime: formData.get("schedule_time"),
      }
    }

    if (formData.get("tags")) {
      data.tags = refactorCreateContactTags(formData.get("tags"))
    }

    socialLinkRows.map(socialLinkRow => {
      if (formData.get(`socialLinks[${socialLinkRow}][url]`)) {
        data.metaData.push({
          type: formData.get(`socialLinks[${socialLinkRow}][type]`),
          url: formData.get(`socialLinks[${socialLinkRow}][url]`),
          permission: PRIVACY_PUBLIC,
        })
      }
    })

    settingCustomFields.map((customFieldRow, customFieldRowIndex) => {
      if (formData.get(`customFields[${customFieldRowIndex}][customFieldId]`)) {
        data.customFields.push({
          customFieldId: formData.get(`customFields[${customFieldRowIndex}][customFieldId]`),
          value: formData.get(`customFields[${customFieldRowIndex}][value]`),
          permission: PRIVACY_PUBLIC,
        })
      }
    })

    if (typeof groupId !== "undefined") {
      console.log(groupId)
      data.groupId = parseInt(groupId)
    }

    setTimeout(() => {}, 300)
    validateAndSaveContact(data)
  }

  const validateAndSaveContact = data => {
    let error = false
    if (!data.name) {
      setNameError("Please Enter Contact Name")
      error = true
    }
    if (data.email && !validator.isEmail(data.email)) {
      setEmailErrors(["Please Enter Valid Email"])
      error = true
    }

    if (data.phone.phone && !validatePhone(data.phone.phone)) {
      error = true
      setPhoneErrors(["Please Enter Valida Phone Number with country code."])
    }

    if (!error) {
      setTimeout(() => {}, 300)
      saveContact(data)
    }
  }

  const saveContact = data => {
    clearErrorMessages()

    return new Promise(async () => {
      await AxiosInstance.post(`/contact/add`, data)
        .then(function (response) {
          if (response.status === 201) {
            navigate("/contacts/detail/" + response.data.contact)
            return false
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setErrorMessages(error.response.data)
          } else if (error.response.data) {
            setFormError(error.response.data.detail)
          }
        })
    })
  }

  const clearErrorMessages = () => {
    setFormError("")
    setNameError("")
    setEmailErrors([])
    setPhoneErrors([])
    setAddressErrors([])
  }

  const onImageChange = e => {
    setUploadedFile(e.target.files[0])
    setUploadFile(true)
  }

  const handleUpload = () => {
    let formData = new FormData()
    formData.append("ProfilePic", uploadedFile)
    formData.append("user_id", getLoginUserId())
    formData.append("parent_id", "0")
    AxiosInstance.post(`/settings/general-setting/upload-file-r2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          setUploadedFilePath(response.data.profilePic.filePath)
          setUploadedFile(response.data.profilePic.filePath)
        }
      })
      .catch(function (error) {})
  }

  const setErrorMessages = data => {
    if (data.name) {
      setNameError(data.name)
    }

    if (data.email) {
      setEmailErrors([data.email])
    }

    if (data.phone) {
      let phoneErrors = []
      if (data.phone.permission) {
        phoneErrors.push(data.phone.permission)
      }
      if (data.phone.phone) {
        phoneErrors.push(data.phone.phone)
      }
      if (data.phone.type) {
        phoneErrors.push(data.phone.type)
      }
      setPhoneErrors(phoneErrors)
    }

    if (data?.socialLinks?.length > 0) {
      let tmpSocialLinkErrors = []
      data.socialLinks.map((link, linkIndex) => {
        let tmpSocialLinkError = []
        if (link.permission) {
          tmpSocialLinkError.push(link.permission)
        }
        if (link.url) {
          tmpSocialLinkError.push(link.url)
        }
        if (link.type) {
          tmpSocialLinkError.push(link.type)
        }

        tmpSocialLinkErrors[linkIndex] = tmpSocialLinkError
      })
      setSocialLinkErrors(tmpSocialLinkErrors)
    }
  }

  const [sequenceRowActive, setSequenceRowActive] = useState(false),
    [taskRowActive, setTaskRowActive] = useState(false),
    [financeRowActive, setFinanceRowActive] = useState(false),
    [calendarRowActive, setCalendarRowActive] = useState(false)

  const contactImage = useRef(null)
  const openSelectImageBox = () => {
    contactImage.current.click()
  }

  useEffect(() => {
    if (loadSettingCustomFields) {
      setLoadSettingCustomFields(false)
      getSettingCustomFields()
    }

    if (uploadFile) {
      setUploadFile(false)
      handleUpload()
    }
  })

  return (
    <>
      <Layout>
        <div id="right-section" className="h-100">
          <Form onSubmit={submitContact} id={"contactForm"}>
            <div className="container-fluid" id="addContactTemplate">
              <div className="row g-0">
                <div className="col">
                  <div className="row">
                    <div className="col-lg-12">
                      <h1 className="text-center mb-4 mt-3">
                        <i className="bi bi-person-plus-fill" /> Add Contact
                      </h1>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-4 my-3" id="contactDetailTemplate">
                      <div className="pageTitle_Editor">
                        <button
                          className="CD_UserType"
                          style={{ position: "initial" }}
                          data-bs-toggle="tooltip"
                          title={contactType === CONTACT_TYPE_HUMAN ? "Human" : "Company"}
                          // onClick={() => {
                          //   updateContactType(contactType, setContactType)
                          // }}
                          onClick={() => {
                            contactType === CONTACT_TYPE_HUMAN
                              ? setContactType(CONTACT_TYPE_COMPANY)
                              : setContactType(CONTACT_TYPE_HUMAN)
                          }}
                        >
                          <i
                            className={
                              contactType === CONTACT_TYPE_HUMAN ? "bi bi-person-fill" : "bi bi-building-fill"
                            }
                          />
                        </button>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-lg-3 my-4">
                      <div className="mb-3 ">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Contact Name"
                          name="name"
                        />
                        {nameError && <div className="mb-3 error-text">{nameError}</div>}
                      </div>

                      <div className="mb-3">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                          name="companyName"
                        />
                      </div>

                      <div className="mb-3">
                        <Form.Control
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name={"email"}
                        />
                        {emailErrors && (
                          <React.Fragment>
                            {emailErrors.map(emailError => (
                              <div className="mb-3 error-text">{emailError}</div>
                            ))}
                          </React.Fragment>
                        )}
                      </div>

                      <div className="mb-3">
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Job Title"
                          name="jobTitle"
                        />
                      </div>

                      <div className="input-group mb-3">
                        <div className="dropdown addNewPhoneBox">
                          <button
                            className="btn btn-default dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className={selectedPhoneIcon} />
                            <span>{selectedPhoneLabel}</span>
                          </button>
                          <ul className="dropdown-menu">
                            {CONTACT_PHONE_OPTIONS.map((phoneData, phoneDataIndex) => {
                              return (
                                <li
                                  key={phoneDataIndex}
                                  onClick={() => {
                                    setSelectedPhoneIcon(phoneData.icon)
                                    setSelectedPhoneLabel(phoneData.label)
                                    setSelectedPhoneType(phoneData.type)
                                  }}
                                >
                                  <a className="dropdown-item" href="#">
                                    <i className={phoneData.icon} /> {phoneData.label}
                                  </a>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder={DEFAULT_CONTACT_PHONE_OPTION.placeHolder}
                          name="phone"
                        />

                        {phoneErrors && (
                          <React.Fragment>
                            {phoneErrors.map(phoneError => (
                              <div className="mb-3 error-text">{phoneError}</div>
                            ))}
                          </React.Fragment>
                        )}
                      </div>

                      <div className="input-group mb-3">
                        <div className="dropdown addNewPhoneBox">
                          <button
                            className="btn btn-default dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-house-door-fill" />
                            <span>{selectedAddressLabel}</span>
                          </button>
                          <ul className="dropdown-menu">
                            {CONTACT_ADDRESS_OPTIONS.map((addressData, addressDataIndex) => {
                              return (
                                <li
                                  key={addressDataIndex}
                                  onClick={() => {
                                    setSelectedAddressLabel(addressData.label)
                                    setSelectedAddressType(addressData.type)
                                  }}
                                >
                                  <a className="dropdown-item" href="#">
                                    <i className={addressData.icon} /> {addressData.label}
                                  </a>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                        <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <>
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: "location-search-input form-control",
                                  name: "address",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item"
                                  const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" }
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            </>
                          )}
                        </PlacesAutocomplete>
                        {/* <Form.Control
                          type="text"
                          className="form-control"
                          placeholder={DEFAULT_CONTACT_ADDRESS_OPTION.placeHolder}
                          name="address"
                        /> */}

                        {addressErrors && (
                          <React.Fragment>
                            {addressErrors.map(addressError => (
                              <div className="mb-3 error-text">{addressError}</div>
                            ))}
                          </React.Fragment>
                        )}
                      </div>

                      {/* <div className="input-group mb-3">
                      <span className="input-group-text" style={{ width: "120px" }}>
                        Role
                        <span
                          className="ms-1"
                          data-toggle="tooltip"
                          title="Can change anytime - most likely role for this person."
                        >
                          <i className="bi bi-question-circle-fill" />
                        </span>
                      </span>
                      <Form.Select className="form-select" name="role">
                        <option value="">== List of Role ==</option>
                        <option value={"Temp Role 1"}>Temp Role 1</option>
                        <option value={"Temp Role 2"}>Temp Role 2</option>
                        <option value={"Temp Role 3"}>Temp Role 3</option>
                      </Form.Select>
                    </div> */}
                    </div>

                    <div className="col-lg-3 my-4">
                      <div className="mb-3">
                        <ContactAddTag
                          addTag={addTag}
                          setAddTag={setAddTag}
                          newTag={newTag}
                          setNewTag={setNewTag}
                          tempTags={tempTags}
                          setTempTags={setTempTags}
                          removeTagIndex={removeTagIndex}
                          setRemoveTagIndex={setRemoveTagIndex}
                        />
                      </div>

                      {socialLinkRows.map((rowId, loopIndex) => {
                        return (
                          <ContactAddSocialRow
                            key={rowId}
                            rowId={rowId}
                            socialLinkRows={socialLinkRows}
                            setSocialLinkRows={setSocialLinkRows}
                            socialLinkErrors={socialLinkErrors}
                            loopIndex={loopIndex}
                          />
                        )
                      })}

                      <div className="mb-3 addNewContactSocialContainer">
                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            placeholder="Notes"
                            id="Notes"
                            style={{ height: "100px" }}
                            name="note"
                          />
                          <label htmlFor="Notes">Notes</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 my-4">
                      <div className="mb-3 text-center">
                        <OverlayTrigger placement="top" overlay={<Tooltip>Add to Sequence</Tooltip>}>
                          <a
                            onClick={() => setSequenceRowActive(!sequenceRowActive)}
                            className={
                              sequenceRowActive
                                ? "btnAddNewBlueDashed btnAddNewSequence active"
                                : "btnAddNewBlueDashed btnAddNewSequence"
                            }
                          >
                            <i className="bi bi-three-dots" />
                          </a>
                        </OverlayTrigger>
                        &nbsp;
                        <OverlayTrigger placement="top" overlay={<Tooltip>Add Task</Tooltip>}>
                          <a
                            onClick={() => setTaskRowActive(!taskRowActive)}
                            className={
                              taskRowActive
                                ? "btnAddNewBlueDashed btnAddNewTask active"
                                : "btnAddNewBlueDashed btnAddNewTask"
                            }
                          >
                            <i className="bi bi-check-lg" />
                          </a>
                        </OverlayTrigger>
                        &nbsp;
                        {/* <a
                        onClick={() => setCalendarRowActive(!calendarRowActive)}
                        className={
                          calendarRowActive
                            ? "btnAddNewBlueDashed btnAddNewEvent active"
                            : "btnAddNewBlueDashed btnAddNewEvent"
                        }
                        data-toggle="tooltip"
                        title="Add Calendar Event"
                      >
                        <i className="bi bi-calendar-plus" />
                      </a> */}
                        {/* &nbsp;
                      <a
                        onClick={() => setFinanceRowActive(!financeRowActive)}
                        className={
                          financeRowActive
                            ? "btnAddNewBlueDashed btnAddMoney active"
                            : "btnAddNewBlueDashed btnAddMoney"
                        }
                        data-toggle="tooltip"
                        title="Add Money"
                      >
                        <i id="currencyIcon" className="bi bi-currency-rupee" />
                      </a> */}
                      </div>

                      <div
                        className={
                          sequenceRowActive
                            ? "input-group mb-3 sequenceRow active"
                            : "input-group mb-3 sequenceRow d-none"
                        }
                      >
                        <span className="input-group-text" style={{ width: "120px" }}>
                          Sequence
                        </span>
                        <Form.Select className="form-select" name={"contactSequence"}>
                          <option>== List of Sequence</option>
                          <option value={"Temp Sequence 1"}>Temp Sequence 1</option>
                          <option value={"Temp Sequence 2"}>Temp Sequence 2</option>
                          <option value={"Temp Sequence 3"}>Temp Sequence 3</option>
                        </Form.Select>
                      </div>
                      <div
                        className={
                          taskRowActive
                            ? "input-group mb-3 taskRow active"
                            : "input-group mb-3 taskRow d-none"
                        }
                      >
                        <span className="input-group-text" style={{ width: "120px" }}>
                          Tasks
                        </span>
                        <Form.Select className="form-select" name={"task"}>
                          <option>== List of Tasks</option>
                          <option value={"Temp Task 1"}>Temp Task 1</option>
                          <option value={"Temp Task 2"}>Temp Task 2</option>
                          <option value={"Temp Task 3"}>Temp Task 3</option>
                        </Form.Select>
                      </div>
                      <div
                        className={
                          financeRowActive
                            ? "input-group mb-3 financeRow active"
                            : "input-group mb-3 financeRow d-none"
                        }
                      >
                        <span className="input-group-text">
                          <i className="bi bi-currency-rupee" />
                        </span>
                        <input className="form-control" type="number" name="singlePrice" />
                        <Form.Select className="form-select" name="invoiceType" id="invoiceTypeOpts">
                          <option value="Invoice">Invoice</option>
                          <option value="Estimate" selected="selected">
                            Estimate
                          </option>
                          <option value="Proposal">Proposal</option>
                          <option value="Quote">Quote</option>
                          <option value="Purchase_Order">Purchase Order</option>
                          <option value="Credit_Memo">Credit Memo</option>
                          <option value="Receipt">Receipt</option>
                        </Form.Select>
                        <Form.Select className="form-select" name="invoiceStatus" id="invoice_status_opts">
                          <option value="Due">Due</option>
                          <option value="Paid_Partial">Paid Partial</option>
                          <option value="Paid">PAID</option>
                          <option value="Payment_Pending">Payment Pending</option>
                          <option value="Canceled">Canceled</option>
                          <option value="Refunded">Refunded</option>
                          <option value="Unpaid_Overdue">Unpaid Overdue</option>
                          <option value="Not_Yet_Committed" selected="selected">
                            Not Yet Committed
                          </option>
                        </Form.Select>
                      </div>
                      {/* <div className="input-group mb-3">
                      <span className="input-group-text" style={{ width: "120px" }}>
                        Job Role
                      </span>
                      <Form.Select className="form-select" name="jobRole">
                        <option value={""}>== List of Role</option>
                        <option value={"Temp Job Role 1"}>Temp Job Role 1</option>
                        <option value={"Temp Job Role 2"}>Temp Job Role 2</option>
                        <option value={"Temp Job Role 3"}>Temp Job Role 3</option>
                      </Form.Select>
                    </div>
                    <div className="input-group mb-3">
                      <div>Rave Score : &nbsp;</div>
                      <SliderRatting defaultRatting={0} ratingName={"raveScore"} />
                    </div> */}
                    </div>

                    <div className="col-lg-3 my-4">
                      <div className="mb-3 text-center">
                        <div className="addNewContactAvatar d-inline-block">
                          <img
                            src={`${process.env.GATSBY_WRANGLER_URL}${uploadedFilePath}`}
                            alt="Contact avatar"
                            className="contact_pic"
                          />
                          <a className="btnEditAvatar" onClick={openSelectImageBox}>
                            <span className="d-block" data-bs-toggle="tooltip" title="Contact avatar">
                              <i className="bi bi-pencil-fill" />
                            </span>
                          </a>

                          <input type="file" ref={contactImage} onChange={onImageChange} hidden />
                        </div>
                      </div>
                    </div>
                  </div>

                  {settingCustomFields.length > 0 && <CustomFields customFields={settingCustomFields} />}

                  <div
                    className={
                      calendarRowActive ? "row calendarRow pt-3 active" : "row calendarRow pt-3 d-none"
                    }
                  >
                    <div className="col-lg-12">
                      <h2>Calendar Form</h2>
                      <div className="row align-items-center mb-3">
                        <div className="col-lg-12 wantScheduleContainer">
                          <div className="row row-cols-lg-auto g-1 mb-1 align-items-center">
                            <div className="col-12">
                              <input
                                type="radio"
                                name="buy_opts"
                                id="eCommerceProduct"
                                className="css-checkbox"
                                value="Scheduler"
                                checked="checked"
                              />
                              <label htmlFor="eCommerceProduct" className="css-radio-label">
                                {" "}
                                Appointment{" "}
                              </label>
                            </div>
                            <div className="col-12">for/with</div>
                            <div className="col-12">
                              <select className="form-select" name="scheduler_list" id="scheduler_list">
                                <optgroup label="== By Task / Job / Sales Opportunity ==" />
                                <optgroup label="== By Scheduler ==" />
                                <optgroup label="Add new scheduler" />
                                <optgroup label="== Other ==">
                                  <option value="Marketing &amp; Scheduled Messages" data-type="other">
                                    Marketing &amp; Scheduled Messages
                                  </option>
                                </optgroup>
                              </select>
                            </div>
                          </div>
                          <div className="row row-cols-lg-auto g-1 align-items-center">
                            <div className="col-12">
                              <input
                                type="radio"
                                name="buy_opts"
                                id="cryptoCurrency"
                                className="css-checkbox"
                                value="Other"
                              />
                              <label htmlFor="cryptoCurrency" className="css-radio-label">
                                Other / Manual Appointment
                              </label>
                            </div>
                            <div className="col-12">for</div>
                            <div className="col-12">
                              <input
                                type="text"
                                className="form-control"
                                name="event_title"
                                id="event_title"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              <i className="bi bi-person-plus-fill" />
                            </span>
                            <select name="inviteShareOpt" id="inviteShareOpt" className="form-select">
                              <option value="inviteShareOpt2">
                                Send Invitation to Contact Above (Allow Cancel)
                              </option>
                              <option value="inviteShareOpt3">
                                Send Invitation to Contact Above (Disallow Self Cancellation)
                              </option>
                              <option value="inviteShareOpt4">
                                Attach Contact Above (Not Visible nor Editable by Contact)]
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row schedulerServiceOptsShow mx-auto pt-4 g-0">
                        <div className="col-lg-12 mx-auto">
                          <div className="row">
                            <div className="col-lg-8 mb-3">
                              <div className="row">
                                <div className="col-lg-7">
                                  <div id="addNewEventCalender" className="calendar" />
                                </div>
                                <div className="col-lg-5 mt-3 mt-lg-0">
                                  <strong className="selectEventTime">
                                    Please select a day to see available times
                                  </strong>
                                  <Form.Select
                                    name="schedule_time"
                                    id="addEventTime"
                                    className="form-control display-none"
                                    multiple=""
                                    size="3"
                                    style={{ height: "290px" }}
                                  >
                                    <option value="9:00 am">9:00 am</option>
                                    <option value="10:00 am">10:00 am</option>
                                    <option value="11:00 am">11:00 am</option>
                                    <option value="11:30 am">11:30 am</option>
                                    <option value="12:00 pm">12:00 pm</option>
                                    <option value="12:30 pm">12:30 pm</option>
                                    <option value="1:00 pm">1:00 pm</option>
                                    <option value="1:30 pm">1:30 pm</option>
                                    <option value="2:00 pm">2:00 pm</option>
                                    <option value="2:30 pm">2:30 pm</option>
                                    <option value="3:00 pm">3:00 pm</option>
                                    <option value="3:30 pm">3:30 pm</option>
                                    <option value="4:00 pm">4:00 pm</option>
                                    <option value="4:30 pm">4:30 pm</option>
                                    <option value="5:00 pm">5:00 pm</option>
                                    <option value="5:30 pm">5:30 pm</option>
                                    <option value="6:00 pm">6:00 pm</option>
                                  </Form.Select>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                              <div className="bg-light-yellow">
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                    <h3 className="step-sidebar-title">May we suggest...</h3>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 p-l-r-15">
                                    <ul className="step3-time-suggest">
                                      <li>
                                        <strong>Soonest Available</strong>
                                        <div className="mb-2">
                                          <input
                                            type="radio"
                                            name="suggested_date"
                                            id="suggested_date1"
                                            className="css-checkbox"
                                            value="soonestAvailable"
                                            data-value="Wed, Aug 28, 2019 at 3:00 pm"
                                          />
                                          <label htmlFor="suggested_date1" className="css-radio-label">
                                            Today 3:00 pm
                                            <br />
                                            1/28/2022
                                            <br />
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <strong>Closest to this time</strong>
                                        <div className="mb-2">
                                          <input
                                            type="radio"
                                            name="suggested_date"
                                            id="suggested_date2"
                                            className="css-checkbox"
                                            value="soonestSameTime"
                                            data-value="Thu, Aug 29, 2019 at 11:00 am"
                                          />
                                          <label htmlFor="suggested_date2" className="css-radio-label">
                                            Tomorrow 11:00 am
                                            <br />
                                            1/29/2022
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <strong>or perhaps...</strong>
                                        <div className="mb-2">
                                          <input
                                            type="radio"
                                            name="suggested_date"
                                            id="suggested_date3"
                                            className="css-checkbox"
                                            value="soonestSameDay"
                                            data-value="Fri, Aug 30, 2019 at 2:00 pm"
                                          />
                                          <label htmlFor="suggested_date3" className="css-radio-label">
                                            Monday on 2:00 pm
                                            <br />
                                            1/31/2022
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="event_note"
                                id="event_note"
                                placeholder="Enter note here..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {formError && (
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <div className="col-lg-12">{formError}</div>
                      </div>
                    </div>
                  )}

                  <div className="row mt-5">
                    <div className="col-lg-12 text-center mt-4">
                      <Button className="btn btn-primary btn-lg" type={"submit"}>
                        <i className="bi bi-check2" />
                        Add / Save <i className="bi bi-person-plus-fill" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Layout>
    </>
  )
}

export default ContactAdd
